'use strict';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Vars

const siteBody = document.querySelector('body');
const siteWrapper = document.querySelector('.site');

// Main navigation toggle

const navItemsWithChildren = document.querySelectorAll('.menu-item-has-children');
const allLinks = document.querySelectorAll('.menu-item-has-children a')
const allSubNavs = document.querySelectorAll('.sub-menu');

// Updated nav

const mobHeader = document.querySelector('.mob-header-outer');
const mobNavToggle = document.querySelector('.mob-header-btn--nav');

mobNavToggle.addEventListener('click', function(){
  if(mobHeader.classList.contains('nav-is-open')){
    mobHeader.classList.remove('nav-is-open');
    allLinks.forEach(link => {
      link.classList.remove('is-active');
    });
    allSubNavs.forEach(subNav => {
      subNav.classList.remove('is-open');
    });
  } else {
    mobHeader.classList.add('nav-is-open');
  }
});

// Sub-nav toggle

navItemsWithChildren.forEach(navItem => {
  const currentLink = navItem.querySelector('a');
  const currentSubNav = navItem.querySelector('.sub-menu');

  currentLink.addEventListener('click', function(e){

    if(window.innerWidth > 1024) {
      return false;
    }

    e.target.classList.toggle('is-active');
    currentSubNav.classList.toggle('is-open');
    
    e.preventDefault;
  }); 
    
});

// Dropdown toggle

const dropdowns = document.querySelectorAll('.dropdown-outer');

dropdowns.forEach(dropdown => {
  const dropdownToggle = dropdown.querySelector('.dropdown-toggle');
  const dropdownList = dropdown.querySelector('.dropdown');

  dropdownToggle.addEventListener('click', function(){
    this.classList.toggle('is-active');
    dropdownList.classList.toggle('is-open');
  });

});

// Close dropdown on link selection

const heroDropdownLinks = document.querySelectorAll('.hero-dropdown__list .dropdown__link');

heroDropdownLinks.forEach(link => {

  if(window.innerWidth > 768) {
    return false;
  }

  link.addEventListener('click', function(){

    dropdowns.forEach(dropdown => {
      const dropdownToggle = dropdown.querySelector('.dropdown-toggle');
      const dropdownList = dropdown.querySelector('.dropdown');
    
      dropdownToggle.classList.remove('is-active');
      dropdownList.classList.remove('is-open');
    });

  });

})

// Search toggle

const searchToggles = document.querySelectorAll('.search-toggle');
const searchModal = document.querySelector('.search-modal');
const closeSearchModal = document.querySelector('.close-search-modal');

searchToggles.forEach(searchToggle => {
  searchToggle.addEventListener('click', function(){
    searchModal.classList.add('is-open');
    searchModal.setAttribute('aria-hidden', 'false');
    siteBody.classList.add('no-scroll');
    siteWrapper.setAttribute('aria-hidden', 'true');
  });
});

closeSearchModal.addEventListener('click', function(){
  searchModal.classList.remove('is-open');
  searchModal.setAttribute('aria-hidden', 'true');
  siteBody.classList.remove('no-scroll');
  siteWrapper.setAttribute('aria-hidden', 'false');
});


// Product info toggles

const productInfoSections = document.querySelectorAll('.product-info-section');

productInfoSections.forEach(productInfoSection => {
  const productInfoToggle = productInfoSection.querySelector('.product-info-toggle');
  const productInfoContent = productInfoSection.querySelector('.product-info');

  productInfoToggle.addEventListener('click', function() {
    this.classList.toggle('is-active');
    productInfoContent.classList.toggle('is-open');
  });
});

// Filter toggle

const filterToggle = document.querySelector('.filter-toggle');
const filterList = document.querySelector('.filter-list');

if(filterToggle) {
  filterToggle.addEventListener('click', function(){
    this.classList.toggle('is-active');
    filterList.classList.toggle('is-open');
  });
}

// Toggle comments form

const commentsToggle = document.querySelector('.write-comment');
const commentsForm = document.querySelector('.comments__form');

if(commentsToggle) {
  commentsToggle.addEventListener('click', function(){
    commentsForm.classList.toggle('is-open');
  });
}

// Toggle cancel appointment modal

const followUpActions = document.querySelectorAll('.follow-up-actions');

followUpActions.forEach(function(followUpAction){
  const toggle = followUpAction.querySelector('.btn');
  const cancelText = followUpAction.querySelector('.cancellation-text');

  toggle.addEventListener('click', function(){
    this.classList.toggle('is-active');
    cancelText.classList.toggle('is-open');
  });
});

// Pop-up

const popUp = document.querySelector('.pop-up');
const popUpClose = document.querySelector('.pop-up__close');
const popUpTime = document.querySelector('.pop-up--time');
const popUpScroll = document.querySelector('.pop-up--scroll');
const popUpOnLoad = document.querySelector('.pop-up--on-load');
const popUpStd = document.querySelector('.pop-up--std');


// if(popUp && getCookie('hidepopup') != 'true') {

//   setTimeout(function(){
//     popUp.classList.add('is-visible');
//     popUp.setAttribute('aria-hidden', 'false');
//     siteWrapper.setAttribute('aria-hidden', 'true');
//   }, 18000);

//   popUpClose.addEventListener('click', function() {
//     popUp.classList.remove('is-visible');
//     popUp.setAttribute('aria-hidden', 'true');
//     siteWrapper.setAttribute('aria-hidden', 'false');
//     document.cookie = "hidepopup=true; path=/";
//   });

// }

var closedPopup = false;

if(popUpTime) {
  const popUpTimeValue = parseFloat(popUpTime.dataset.time)*1000;

  setTimeout(function(){
    popUp.classList.add('is-visible');
    popUp.setAttribute('aria-hidden', 'false');
    siteWrapper.setAttribute('aria-hidden', 'true');
    siteBody.classList.add('no-scroll');
  }, popUpTimeValue);

}

document.addEventListener("DOMContentLoaded", function(){

  if(popUpScroll) {
    const bodyHeight = document.body.offsetHeight;
    const popUpHeight = parseFloat(popUp.offsetHeight) / 2;
    const popUpScrollValue = parseFloat(popUpScroll.dataset.scroll) / 100;

    window.addEventListener('scroll', () => {
      if(!closedPopup) {
        const scrollPosition = window.scrollY;
        const popUpTrigger = (bodyHeight - popUpHeight) * popUpScrollValue;
        if (scrollPosition >= popUpTrigger) {
          popUp.classList.add('is-visible');
          popUp.setAttribute('aria-hidden', 'false');
          siteWrapper.setAttribute('aria-hidden', 'true');
          siteBody.classList.add('no-scroll');
        }
      }
    });

  }

});

if(popUpOnLoad) {

  setTimeout(function(){
    popUp.classList.add('is-visible');
    popUp.setAttribute('aria-hidden', 'false');
    siteWrapper.setAttribute('aria-hidden', 'true');
    siteBody.classList.add('no-scroll');
  }, 250);

}

if(popUpStd) {
  const popUpTimeValue = 18000;

  setTimeout(function(){
    popUp.classList.add('is-visible');
    popUp.setAttribute('aria-hidden', 'false');
    siteWrapper.setAttribute('aria-hidden', 'true');
    siteBody.classList.add('no-scroll');
  }, popUpTimeValue);

}

if(popUp) {

  popUpClose.addEventListener('click', function() {
    popUp.classList.remove('is-visible');
    popUp.setAttribute('aria-hidden', 'true');
    siteWrapper.setAttribute('aria-hidden', 'false');
    siteBody.classList.remove('no-scroll');
    if(window.location.pathname == '/') {
    document.cookie = "hidepopup_home=true; path=" + window.location.pathname;
    } else {
      document.cookie = "hidepopup=true; path=" + window.location.pathname;
    }
    
    closedPopup = true;
  });

}

// Blog filters

const postFeed = document.querySelector('.post-feed--fixed');
const targetSelector = '.mix';

document.addEventListener("DOMContentLoaded", function(){
  if (postFeed) {
    const mixer = mixitup(postFeed, {
      animation: {
        enable: false,
        animateResizeContainer: false
      },
      pagination: {
        limit: 8,
        hidePageListIfSinglePage: true,
        loop: true
      },
      selectors: {
        pageList: '.pagination--post-feed',
        target: targetSelector
      },
      templates: {
        pagerPrev:
          '<button class="${classNames} pagination__control pagination__control--prev" data-page="prev" aria-label="Previous page"><svg aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path data-name="Polygon 11" d="M76.29 54.12L32 98.28a5.84 5.84 0 01-10-4.11V5.83a5.84 5.84 0 0110-4.11l44.29 44.16a5.82 5.82 0 010 8.24z"/></svg></button>',
        pagerNext:
          '<button class="${classNames} pagination__control pagination__control--next" data-page="next" aria-label="Next page"><svg aria-hidden="true" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path data-name="Polygon 11" d="M76.29 54.12L32 98.28a5.84 5.84 0 01-10-4.11V5.83a5.84 5.84 0 0110-4.11l44.29 44.16a5.82 5.82 0 010 8.24z"/></svg></button>',
        pager:
          '<button class="${classNames} pagination__pg" data-page="${pageNumber}">${pageNumber}</button>'
      },
      callbacks: {
        onMixEnd: function(state){
          window.scrollTo(0, 0);
        }
      }
    });
  }
});

// Doctor filters

const teamFeed = document.querySelector('.team-feed--doctors');

document.addEventListener("DOMContentLoaded", function(){
  if (teamFeed) {
    const mixer = mixitup(teamFeed, {
      animation: {
        enable: false,
        animateResizeContainer: false
      }
    });
  }
});

// Sliders

const servicesSlider = document.querySelector('.services-slider');

if(servicesSlider) {

  const servicesSliderSlides = tns({
    container: servicesSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.services-slider-control.slider-control--prev',
    nextButton: '.services-slider-control.slider-control--next',
    
    responsive: {
      650: {
        items: 2,
        gutter: 20,
      },
      1024: {
        items: 3
      },
    }
  });

}

const productCatsSlider = document.querySelector('.product-cats');

if(productCatsSlider) {

  const productCatsSliderSlides = tns({
    container: productCatsSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.product-cats-slider-control.slider-control--prev',
    nextButton: '.product-cats-slider-control.slider-control--next',
    
    responsive: {
      650: {
        items: 2,
        gutter: 20,
      },
      768: {
        items: 3
      },
      1024: {
        items: 4
      },
      1280: {
        items: 6
      },
    }
  });

}

const productGridSliders = document.querySelectorAll('.product-grid-outer .products');

productGridSliders.forEach(productGridSlider => {
  const productGridSliderSlides = tns({
    container: productGridSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.product-grid-outer .product-grid-slider-control.slider-control--prev',
    nextButton: '.product-grid-outer .product-grid-slider-control.slider-control--next',
    
    responsive: {
      650: {
        items: 2,
        gutter: 20,
      },
      1024: {
        items: 3
      },
      1280: {
        items: 4
      },
    }
  })
})

const imgGallerySlider = document.querySelector('.img-gallery--static');

if(imgGallerySlider) {

  const imgGallerySliderSlides = tns({
    container: imgGallerySlider,
    mode: 'gallery',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.img-gallery-control.slider-control--prev',
    nextButton: '.img-gallery-control.slider-control--next',
  });

}

const imgGallerySliderAuto = document.querySelector('.img-gallery--auto');

if(imgGallerySliderAuto) {

  const imgGallerySliderSlides = tns({
    container: imgGallerySliderAuto,
    mode: 'carousel',
    items: 1,
    slideBy: '1',
    autoplay: 'true',
    autoplayTimeout: 3000,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.img-gallery-control.slider-control--prev',
    nextButton: '.img-gallery-control.slider-control--next',
  });

}

const relatedContentSlider = document.querySelector('.slider-outer .post-feed');

if(relatedContentSlider) {

  const relatedContentSliderSlides = tns({
    container: relatedContentSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.related-feed-control.slider-control--prev',
    nextButton: '.related-feed-control.slider-control--next',
    responsive: {
      650: {
        items: 2,
        gutter: 10,
      },
      1024: {
        items: 3
      },
    }

  });

}

const productSlider = document.querySelector('.product-gallery');

if(productSlider) {

  const productSliderSlides = tns({
    container: productSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: false,
    nav: true,
    mouseDrag: true,
    touch: true,
    navContainer: '.slider-dots',
  });

}

const testimonialSlider = document.querySelector('.testimonial-slider');

if(testimonialSlider) {

  const testimonialSliderSlides = tns({
    container: testimonialSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.testimonial-slider-control.slider-control--prev',
    nextButton: '.testimonial-slider-control.slider-control--next',
    mouseDrag: true,
    touch: true,
    // navContainer: '.slider-dots',
  });

}

const homeHeroCarousel = document.querySelector('.hero-cta__carousel');

if(homeHeroCarousel) {

  const homeHeroCarouselSlides = tns({
    container: homeHeroCarousel,
    mode: 'gallery',
    autoplay: true,
    autoplayButtonOutput: false,
    speed: 1000,
    controls: false,
    nav: false,
    // loop: true,
    // autoWidth: true,
  });

}

const homeTestimonialSlider = document.querySelector('.quote-block--slider');

if(homeTestimonialSlider) {

  const homeTestimonialSliderSlides = tns({
    container: homeTestimonialSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    speed: 500,
    controls: false,
    nav: false,
    mouseDrag: true,
    touch: true,
    center: true
  });

}

const partnerLogoSliders = document.querySelectorAll('.partner-logos__carousel');

partnerLogoSliders.forEach(partnerLogoSlider => {

  const partnerLogoSliderSlides = tns({
    container: partnerLogoSlider,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    speed: 4000,
    controls: false,
    nav: false,
    mouseDrag: true,
    touch: true,
    responsive: {
      450: {
        items: 2
      },
      768: {
        items: 4
      },
      1024: {
        items: 6
      }
    }
  });

});

// Symptom checker

const symptomChecker = document.querySelector('.symptom-checker');
const togglesContainer = document.querySelector('.symptom-list');
const toggles = document.querySelectorAll(".symptom-checker a");
const organs = document.querySelectorAll(".symptom-diagram__organ");
const symptoms = document.querySelectorAll(".symptom-summary");
const mqLaptop = window.matchMedia('(min-width: 1024px)');

function symptomsOrientationChange(resizeEvent) {
 
//   // Check window is wide enough

  if (resizeEvent.matches) {

    toggles.forEach(function (toggle) {

      toggle.addEventListener("mouseenter", e => {
        let organs = e.target.classList;

        for (const organ of organs) {
          
          let organToHighlight = document.querySelector(
            '[data-organ="' + organ + '"]'
          );

          organToHighlight.classList.add("is-highlighted");
        }
      });

      // Remove highlight off hover

      toggle.addEventListener("mouseleave", e => {
        resetHighlightedOrgans();
      });

      // Trigger summary modal on click

      toggle.addEventListener("click", e => {
        symptomChecker.classList.add('summary-is-visible');

        let organs = e.target.classList;

        // Remove active state from selected items

        const currentSymptom = document.querySelector(".symptom-summary.is-active");

        if (currentSymptom) {
          currentSymptom.classList.remove("is-active");
        }

        resetSelectedOrgans();

        const symptomToShow = document.querySelector(
          '[data-symptom="' + e.target.dataset.symptomToggle + '"]'
        );

        symptomToShow.classList.add("is-active");

        // Prevent default functionality of link tag

        e.preventDefault();
      });
    });
  } else {
    resetHighlightedOrgans();
    resetSelectedOrgans();
  }

}

// Switch off symptom checker for IE11
// If the browser ISN'T IE11 run the symptom checker functions

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

if ( !isIE ) {
  mqLaptop.addEventListener('change', symptomsOrientationChange);
  symptomsOrientationChange(mqLaptop);
}

// Close symptom summary

const closeSymptomSummaryBtns = document.querySelectorAll('.close-symptom-summary');

closeSymptomSummaryBtns.forEach(function (closeSymptomSummaryBtn){
  closeSymptomSummaryBtn.addEventListener('click', function(){
    document.querySelector(".symptom-summary.is-active").classList.remove('is-active');
    symptomChecker.classList.remove('summary-is-visible');
    resetSelectedOrgans();
    resetHighlightedOrgans();
  });
})

// Reset organs

function resetHighlightedOrgans() {
  for (const organ of organs) {
    organ.classList.remove("is-highlighted");
  }
}

function resetSelectedOrgans() {
  for (const organ of organs) {
    organ.classList.remove("is-highlighted", "is-selected");
  }
}


// Accordion

let accordion = '';
const accordionItems = document.querySelectorAll('.accordion-item');
const accordionToggles = document.querySelectorAll('.accordion-item__toggle');

accordionItems.forEach(function (accordionItem, index) {
  const currentAccordionToggle = accordionItem.querySelector('.accordion-item__toggle');
  const currentAccordionContent = accordionItem.querySelector('.accordion-item__content');
  
    currentAccordionToggle.addEventListener('click', function(){
      
      // remove is-active from all here
      accordionToggles.forEach(function (toggle, index) {
        toggle.classList.remove('is-active');
      });
      
      const accordionContents = document.querySelectorAll('.accordion-item__content');
      
      accordionContents.forEach(function(accordionContent, index) {
        accordionContent.classList.remove('is-open');
      });
      
      if(accordion != currentAccordionContent.id) {
        currentAccordionContent.classList.add('is-open');
        // then add is-active to the clicked one
        currentAccordionToggle.classList.add('is-active');
        accordion = currentAccordionContent.id;
      } else {
        accordion = '';
      }
    });
    
});

// Add role="button" attribute to main nav links

const navButtons = document.querySelectorAll('.nav-btn a');

navButtons.forEach(navButton => {
  const roleAttr = document.createAttribute('role');
  const tabIndexAttr = document.createAttribute('tabindex');
  roleAttr.value = 'button';
  tabIndexAttr.value = '0';
  navButton.setAttributeNode(roleAttr);
  navButton.setAttributeNode(tabIndexAttr);
});

// FAQs filter

const faqFeeds = document.querySelectorAll('.faqs-list');

faqFeeds.forEach(faqFeed => {
  if (faqFeed) {
    const mixer = mixitup(faqFeed, {
      animation: {
        enable: false,
        animateResizeContainer: false
      }
    });
  }
});

// Cookies banner

// var dropCookie = true;                  // False disables Cookie for testing
// var cookieDuration = 7;                // Number of days before cookie expires
// var cookieName = 'ssAcceptedCookies';
// var cookieValue = 'on';
 
// function createDiv(){
//     var bodytag = document.getElementsByTagName('body')[0];
//     var div = document.createElement('div');
//     div.setAttribute('class','cookies-banner');
//     // Change href below if your cookie policy page link is not /cookies/
//     div.innerHTML = '<div class="std-content"><p>We use cookies to give you the best experience on our website. By continuing, you agree to our <a href="/privacy-policy/" rel="nofollow">privacy policy</a>.</p></div><a class="btn btn--inline btn--orange" href="javascript:void(0);" onclick="removeMe();">I agree</a>';    
//     bodytag.insertBefore(div,bodytag.firstChild); // Add the banner just after the opening <body> tag
//     createCookie(window.cookieName,window.cookieValue, window.cookieDuration); // Create the cookie
// }  
// function createCookie(name,value,days) {
//     if (days) {
//         var date = new Date();
//         date.setTime(date.getTime()+(days*24*60*60*1000)); 
//         var expires = "; expires="+date.toGMTString(); 
//     }
//     else var expires = "";
//     if(window.dropCookie) { 
//         document.cookie = name+"="+value+expires+"; path=/"; 
//     }
// } 
// function checkCookie(name) {
//     var nameEQ = name + "=";
//     var ca = document.cookie.split(';');
//     for(var i=0;i < ca.length;i++) {
//         var c = ca[i];
//         while (c.charAt(0)==' ') c = c.substring(1,c.length);
//         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
//     }
//     return null;
// } 
// function eraseCookie(name) {
//     createCookie(name,"",-1);
// } 
// window.onload = function(){
//     if(checkCookie(window.cookieName) != window.cookieValue){
//         createDiv(); 
//     }
// }
// function removeMe(){
//     var element = document.querySelector('.cookies-banner');
//     element.parentNode.removeChild(element);
// }

// Back to top button trigger and floating header

let scrollPosition = window.scrollY;
const body = document.querySelector('body');
const bodyHeight = body.clientHeight;
const initialWindowHeight = window.innerHeight;
const backToTopBtn = document.querySelector('.back-to-top');
const desktopHeader = document.querySelector('.pg-header-outer');

if(backToTopBtn) {

  window.addEventListener('scroll', function() {
    scrollPosition = window.scrollY;

    if(bodyHeight > initialWindowHeight) {
      if(scrollPosition > Math.round(400)) {
        backToTopBtn.classList.add('is-visible');
      } else {
        backToTopBtn.classList.remove('is-visible');
      }
    }
  });

}

window.addEventListener('scroll', function() {
  scrollPosition = window.scrollY;

  if(scrollPosition > Math.round(40)) {
    mobHeader.classList.add('is-scrolling');
    desktopHeader.classList.add('is-scrolling');
  } else {
    mobHeader.classList.remove('is-scrolling');
    desktopHeader.classList.remove('is-scrolling');
  }

});

// JQUERY

// Pause sign-up

window.addEventListener("load",function(event) {
  // window.onload = function () {
    $(document).ready(function() {
      $('#show-password-checkbox').on('click', function(e) {
        if($('#show-password-checkbox').is(':checked')) {
          $('#pass1').attr('type', 'text');
        } else {
          $('#pass1').attr('type', 'password');
        }
      });

      var patient_type = '';
      var appointment_type = '';

      $('input[name="patient-type"]').on('change', function() {
        $('.heydoc-iframe-holder').hide();
        $('#doctor-last-seen').hide();
        $('#appointment-type').hide();
        $('#consultation-type').hide();
        $('.new-patient-helper, .existing-two-more-patient-helper, .existing-patient-helper').hide();
        
        $('#appointment-type input, #consultation-type input, input[name="doctor-seen"]').each(function() {
          $(this).prop('checked', false);
        });

        $('select[name="location"]').val('');

        if($('input[name="patient-type"]:checked').val() == 'new-patient') {
          $('#appointment-type').show();
          window.location.href = "#appointment-type";

          patient_type = 'new-patient';
          $('.new-patient-helper').show();
        } else {
          $('#doctor-last-seen').show();
          window.location.href = "#doctor-last-seen";

          patient_type = 'existing-patient';
        }

      });

      $('input[name="doctor-seen"]').on('change', function() {
        $('.heydoc-iframe-holder').hide();
        $('.new-patient-helper, .existing-two-more-patient-helper, .existing-patient-helper').hide();

        $('#appointment-type input, #consultation-type input').each(function() {
          $(this).prop('checked', false);
        });

        $('select[name="location"]').val('');

        if($('input[name="doctor-seen"]:checked').val() == 'more-than') {
          patient_type = 'new-patient';
          $('.existing-two-more-patient-helper').show();
          window.location.href = "#appointment-type";
        } else {
          patient_type = 'existing-patient';
          $('.existing-patient-helper').show();
          window.location.href = "#appointment-type";
        }

        $('#appointment-type').show();
      });

      $('input[name="appointment-type"]').on('change', function() {
        $('#clinic-button').show();

        appointment_type = $('input[name="appointment-type"]:checked').val();

        $('#consultation-type input').each(function() {
          $(this).prop('checked', false);
        });

        $('select[name="location"]').val('');

        $('.heydoc-iframe-holder, #location').hide();

        if(appointment_type == 'nurse') {
          $('#clinic-button').hide();
        }

        $('#consultation-type').show();
        window.location.href = "#consultation-type";
      });

      $('input[name="consultation-type"]').on('change', function() {
        $('#location, #heydoc-iframe-holder').hide();
        $('select[name="location"]').val('');

        var consultation_type = $('input[name="consultation-type"]:checked').val();
        var heydoc_iframe_id = '';

        let booking_key = patient_type + '-' + appointment_type + '-' + consultation_type;

        // if(patient_type == 'new' && consultation_type == 'video') {
        //   var heydoc_iframe_id = '68ff8e43192533653c840288ae432ba17554dfa8';
        // } else if(patient_type == 'new' && consultation_type == 'telephone') {
        //   var heydoc_iframe_id = '07b78472ffd4fcf8cda2c13282cfe43abbaa1894';
        // } else if(patient_type == 'existing' && consultation_type == 'video') {
        //   var heydoc_iframe_id = 'b7b1d984dafbdc0ef99254a72f8206f2be456fd2';
        // } else if(patient_type == 'existing' && consultation_type == 'telephone') {
        //   var heydoc_iframe_id = 'f84043f0cbee0be731449ac868c0a814002f06e0';
        // } else if(consultation_type == 'clinic') {
        //   $('#location').show();
        // }

        if(consultation_type != 'clinic') {
          var heydoc_iframe_id = booking_iframes[booking_key];
          $('#heydoc-iframe').attr('src', 'https://online-booking.semble.io/?token=' + heydoc_iframe_id);

          $('.heydoc-iframe-holder').show();
          window.location.href = "#heydoc-iframe-holder";
        } else {
          $('#location').show();
          window.location.href = "#location";
        }
      });

      $('select[name="location"]').on('change', function() {
        $('.heydoc-iframe-holder').hide();
        $('#external-booking-text').hide();

        var consultation_type = $('input[name="consultation-type"]:checked').val();
        let booking_key = patient_type + '-' + appointment_type + '-' + consultation_type + '-' + this.value;

        var heydoc_iframe_id = booking_iframes[booking_key];

        // console.log(heydoc_iframe_id);

        let external = $(this).find(":selected").data('external');
        let external_url = $(this).find(":selected").data('external-url');
        
        if(external) {
          $('#external-booking-text a').attr('href', external_url);
          $('#external-booking-text span').text($(this).find(':selected').text());
          $('#external-booking-text').show();
        } else {
          $('#heydoc-iframe').attr('src', 'https://online-booking.semble.io/?token=' + heydoc_iframe_id);
          $('.heydoc-iframe-holder').show();
        }
      });

      $('.email-social__submit').on('click', function(e) {
        e.preventDefault();

        $('.form-validation-inline--success--pg-footer, .form-validation-inline--failed--pg-footer').hide();

        var email = $('input[name="pg-footer-email"]').val();

        if(email) {
          $.ajax({
            url: "/api/create-contact.php",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: '{ "email": "' + email + '", "source": "footer-pause-cta" }',
            success: function (data) {
              if('status' in data) {
                if('category' in data && data.category == 'CONFLICT') {
                  $('.form-validation-inline--failed--pg-footer').html('<p>Email already exists</p>');
                } else {
                  $('.form-validation-inline--failed--pg-footer').html('<p>Oops! Looks like this is an invalid email address</p>');
                }

                $('.form-validation-inline--failed--pg-footer').show();
              } else {
                $('.form-validation-inline--success--pg-footer').show(); 
              }
            },
            error: function(){
              $('.form-validation-inline--failed--pg-footer').show();
            }
          });
        } else {
          $('.form-validation-inline--failed--pg-footer').html('<p>Please enter your email</p>');
          $('.form-validation-inline--failed--pg-footer').show();
        }
      });

      $('.sfl-form__submit').on('click', function(e) {
        e.preventDefault();

        $('.form-validation-inline--success--sfl-cta, .form-validation-inline--failed--sfl-cta').hide();

        var email = $('input[name="pop-up-read-later-email"]').val();
        var terms_checkbox = $('input[name="accept-terms"]').val();

        var terms_accepted = 'No';
        if(terms_checkbox) {
          var terms_accepted = 'Yes';
        }

        if(email) {
          $.ajax({
            url: "/api/create-contact.php",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: '{ "email": "' + email + '", "source": "read-later-cta", "popup-terms": "' + terms_accepted + '" }',
            success: function (data) {
              if('status' in data) {
                if('category' in data && data.category == 'CONFLICT') {
                  window.location = 'https://pdfcrowd.com/url_to_pdf/?use_print_media=1&url=' + encodeURIComponent(window.location.href);  
                } else {
                  $('.form-validation-inline--failed--sfl-cta').html('<p>Oops! Looks like this is an invalid email address</p>');
                }
  
                $('.form-validation-inline--failed--sfl-cta').show();
              } else {
                window.location = 'https://pdfcrowd.com/url_to_pdf/?use_print_media=1&url=' + encodeURIComponent(window.location.href);
                $('.form-validation-inline--success--sfl-cta').show(); 
              }
            },
            error: function(){
              $('.form-validation-inline--failed--sfl-cta').show();
            }
          });
        } else {
          $('.form-validation-inline--failed--sfl-cta').html('<p>Please enter your email</p>');
          $('.form-validation-inline--failed--sfl-cta').show();
        }
      });

      $('.pause-cta-form__submit').on('click', function(e) {
        e.preventDefault();

        $('.form-validation-inline--success--pause-cta, .form-validation-inline--failed--pause-cta').hide();

        var email = $('input[name="pause-cta-form-email"]').val();
        var terms_checkbox = $('input[name="accept-terms"]').val();

        var terms_accepted = 'No';
        if(terms_checkbox) {
          var terms_accepted = 'Yes';
        }

        if(email) {
          $.ajax({
            url: "/api/create-contact.php",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: '{ "email": "' + email + '", "source": "pause-cta", "popup-terms": "' + terms_accepted + '" }',
            success: function (data) {
              if('status' in data) {
                if('category' in data && data.category == 'CONFLICT') {
                  $('.form-validation-inline--failed--pause-cta').html('<p>Email already exists</p>');
                } else {
                  $('.form-validation-inline--failed--pause-cta').html('<p>Oops! Looks like this is an invalid email address</p>');
                }
  
                $('.form-validation-inline--failed--pause-cta').show();
              } else {
                $('.form-validation-inline--success--pause-cta').show(); 
              }
            },
            error: function(){
              $('.form-validation-inline--failed--pause-cta').show();
            }
          });
        } else {
          $('.form-validation-inline--failed--pause-cta').html('<p>Please enter your email</p>');
          $('.form-validation-inline--failed--pause-cta').show();
        }
      });

      $('.pop-up__form__submit').on('click', function(e) {
        e.preventDefault();

        $('.form-validation-inline--success--popup, .form-validation-inline--failed--popup').hide();

        var email = $('input[name="pop-up-email"]').val();

        if(email) {
          $.ajax({
            url: "/api/create-contact.php",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: '{ "email": "' + email + '", "source": "popup" }',
            success: function (data) {
              if('status' in data) {
                if('category' in data && data.category == 'CONFLICT') {
                  $('.form-validation-inline--failed--popup').html('<p>Email already exists</p>');
                } else {
                  $('.form-validation-inline--failed--popup').html('<p>Oops! Looks like this is an invalid email address</p>');
                }

                $('.form-validation-inline--failed--popup').show();
              } else {
                $('.form-validation-inline--success--popup').show(); 
              }
            },
            error: function(){
              $('.form-validation-inline--failed--popup').show();
            }
          });
        } else {
          $('.form-validation-inline--failed--popup').html('<p>Please enter your email</p>');
          $('.form-validation-inline--failed--popup').show();
        }
      });

      $('.pause-cta-sidebar__submit').on('click', function(e) {
        e.preventDefault();

        $('.form-validation-inline--success--sidebar, .form-validation-inline--failed--sidebar').hide();

        var email = $('input[name="pause-cta-sidebar"]').val();

        if(email) {
          $.ajax({
            url: "/api/create-contact.php",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: '{ "email": "' + email + '", "source": "sidebar-cta" }',
            success: function (data) {
              if('status' in data) {
                if('category' in data && data.category == 'CONFLICT') {
                  $('.form-validation-inline--failed--sidebar').html('<p>Email already exists</p>');
                } else {
                  $('.form-validation-inline--failed--sidebar').html('<p>Oops! Looks like this is an invalid email address</p>');
                }

                $('.form-validation-inline--failed--sidebar').show();
              } else {
                $('.form-validation-inline--success--sidebar').show(); 
              }
            },
            error: function(){
              $('.form-validation-inline--failed--sidebar').show();
            }
          });
        } else {
          $('.form-validation-inline--failed--sidebar').html('<p>Please enter your email</p>');
          $('.form-validation-inline--failed--sidebar').show();
        }
      });

      $('input[name="pg-cl-submit"]').on('click', function(e) {
        e.preventDefault();

        var formOk = true;

        $('#cl-form .form-validation-inline--success, #cl-form  .form-validation-inline--failed').hide();

        var name = $('input[name="pg-cl-name"]').val();
        var email = $('input[name="pg-cl-email"]').val();
        var phone = $('input[name="pg-cl-phone"]').val();
        var company = $('input[name="pg-cl-organisation"]').val();
        var help = $('textarea[name="pg-cl-help"]').val();

        if(!name) {
          $('#cl-form input[name="pg-cl-name"] + .form-validation-inline--failed').show();
          formOk = false;
        }

        if(!email) {
          $('#cl-form input[name="pg-cl-email"] + .form-validation-inline--failed').html('<p>Please enter your email</p>');
          $('#cl-form input[name="pg-cl-email"] + .form-validation-inline--failed').show();
          formOk = false;
        }

        if(!phone) {
          $('#cl-form input[name="pg-cl-phone"] + .form-validation-inline--failed').show();
          formOk = false;
        }

        if(formOk) {
          $.ajax({
            url: "/api/create-cl-contact.php",
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: '{ "name" : "' + name + '", "email": "' + email + '", "phone" : "' + phone + '", "company" : "' + company + '", "source": "' + $('#cl-form #form-identify').val() + '", "cl_help": " ' + help + '" }',
            success: function (data) { console.log(data);
              if('status' in data) {
                if('category' in data && data.category != 'CONFLICT') {
                  $('#cl-form input[name="pg-cl-email"] + .form-validation-inline--failed').html('<p>Oops! Looks like this is an invalid email address</p>');
                  $('#cl-form input[name="pg-cl-email"] + .form-validation-inline--failed').show();
                }
              } else {
                $('#cl-form .form-validation-inline--success').show(); 
              }
            },
            error: function(){
              $('#cl-form .form-validation-inline--failed').show();
            }
          });
        } else {
          $('#cl-form .form-validation-inline--failed--send').show();
        }
      });
      

      // Product quantity


      $('.form-field-qty--down').on('click', function(e) {
        e.preventDefault();

        var quantity_field = $(this).parent().find('input.qty');

        var current_quantity = $(quantity_field).val();
        var new_quantity = parseInt(current_quantity) + -1;
      
       
      
        if(new_quantity <= 0) {
          new_quantity = 1;
        }
      
       
      
        $(quantity_field).val(new_quantity);
      });

      $('.form-field-qty--up').on('click', function(e) {
        e.preventDefault();

        var quantity_field = $(this).parent().find('input.qty');


        var current_quantity = $(quantity_field).val();
        var new_quantity = parseInt(current_quantity) + 1;
    
    
        $(quantity_field).val(new_quantity);
      });
    });

    $('.add-cart-links').on('click', function() {
      var product_ids = [];

      $('.form-field-pill').each(function(index) {
        if($(this).find('input[name="add-to-cart[]"]:checked').length > 0) {
          product_ids.push($(this).find('input[name="add-to-cart[]"]:checked').val());
        }
      });

      if(product_ids.length > 0) {
        window.location = 'https://www.mymenopausecentre.com/cart/?add-to-cart=' + product_ids.join(',');
      }
    });
  });

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// const pauseCTA = document.querySelector('.pause-cta--sm');

// if(pauseCTA) {
//   const pauseCTAWidth = pauseCTA.offsetWidth;
//   const stickyElement = document.querySelector('.pg-content__section--sticky');

//   stickyElement.style.width = pauseCTAWidth + 'px';

//   const stickybit = stickybits(stickyElement, {useFixed: true, stickyBitStickyOffset: 120 });
// }